import App from "next/app";
import * as React from "react";
import "aos/dist/aos.css";

import { ThemeProvider } from "styled-components";
import { DEFAULT_TEMPLATE } from "../common";
import { GlobalStyle as T1GlobalStyle } from "src/templates/t1/constants/globalStyle";
import { theme as T1Theme } from "src/templates/t1/constants/themes";

import { GlobalStyle as T2GlobalStyle } from "src/templates/t2/constants/globalStyle";
import { theme as T2Theme } from "src/templates/t2/constants/themes";

import { GlobalStyle as T3GlobalStyle } from "src/templates/t3/constants/globalStyle";
import { theme as T3Theme } from "src/templates/t3/constants/themes";

import { GlobalStyle as T4GlobalStyle } from "src/templates/t4/constants/globalStyle";
import { theme as T4Theme } from "src/templates/t4/constants/themes";

import { GlobalStyle as T5GlobalStyle } from "src/templates/t5/constants/globalStyle";
import { theme as T5Theme } from "src/templates/t5/constants/themes";

import { GlobalStyle as T6GlobalStyle } from "src/templates/t6/constants/globalStyle";
import { theme as T6Theme } from "src/templates/t6/constants/themes";

import { GlobalStyle as T7GlobalStyle } from "src/templates/t7/constants/globalStyle";
import { theme as T7Theme } from "src/templates/t7/constants/themes";

import { GlobalStyle as T8GlobalStyle } from "src/templates/t8/constants/globalStyle";
import { theme as T8Theme } from "src/templates/t8/constants/themes";

import { GlobalStyle as T9GlobalStyle } from "src/templates/t9/constants/globalStyle";
import { theme as T9Theme } from "src/templates/t9/constants/themes";

import { GlobalStyle as T10GlobalStyle } from "src/templates/t10/constants/globalStyle";
import { theme as T10Theme } from "src/templates/t10/constants/themes";

import { GlobalStyle as T11GlobalStyle } from "src/templates/t11/constants/globalStyle";
import { theme as T11Theme } from "src/templates/t10/constants/themes";

import { GlobalStyle as T12GlobalStyle } from "src/templates/t12/constants/globalStyle";
import { theme as T12Theme } from "src/templates/t12/constants/themes";

import { GlobalStyle as T13GlobalStyle } from "src/templates/t13/constants/globalStyle";
import { theme as T13Theme } from "src/templates/t13/constants/themes";

import { GlobalStyle as T17GlobalStyle } from "src/templates/t17/constants/globalStyle";
import { theme as T17Theme } from "src/templates/t17/constants/themes";

import { GlobalStyle as T14GlobalStyle } from "src/templates/t14/constants/globalStyle";
import { theme as T14Theme } from "src/templates/t14/constants/themes";

import { GlobalStyle as T15GlobalStyle } from "src/templates/t15/constants/globalStyle";
import { theme as T15Theme } from "src/templates/t15/constants/themes";

import { GlobalStyle as T18GlobalStyle } from "src/templates/t18/constants/globalStyle";
import { theme as T18Theme } from "src/templates/t18/constants/themes";

import { GlobalStyle as T19GlobalStyle } from "src/templates/t19/constants/globalStyle";
import { theme as T19Theme } from "src/templates/t19/constants/themes";

import { GlobalStyle as T16GlobalStyle } from "src/templates/t16/constants/globalStyle";
import { theme as T16Theme } from "src/templates/t16/constants/themes";

import { GlobalStyle as T20GlobalStyle } from "src/templates/t20/constants/globalStyle";
import { theme as T20Theme } from "src/templates/t20/constants/themes";

import { GlobalStyle as T21GlobalStyle } from "src/templates/t21/constants/globalStyle";
import { theme as T21Theme } from "src/templates/t21/constants/themes";

import { GlobalStyle as T22GlobalStyle } from "src/templates/t22/constants/globalStyle";
import { theme as T22Theme } from "src/templates/t22/constants/themes";

import { GlobalStyle as T23GlobalStyle } from "src/templates/t23/constants/globalStyle";
import { theme as T23Theme } from "src/templates/t23/constants/themes";

import { GlobalStyle as T24GlobalStyle } from "src/templates/t24/constants/globalStyle";
import { theme as T24Theme } from "src/templates/t24/constants/themes";

import { GlobalStyle as T25GlobalStyle } from "src/templates/t25/constants/globalStyle";
import { theme as T25Theme } from "src/templates/t25/constants/themes";

import { GlobalStyle as T26GlobalStyle } from "src/templates/t26/constants/globalStyle";
import { theme as T26Theme } from "src/templates/t26/constants/themes";

import { GlobalStyle as T27GlobalStyle } from "src/templates/t27/constants/globalStyle";

import { GlobalStyle as T0GlobalStyle } from "src/templates/t0/constants/globalStyle";
import { theme as T0Theme } from "src/templates/t0/constants/themes";

import { SEO } from "../common/components/SEO";

import { CookieBanner } from "../common/components/CookieBanner/CookieBanner";
import { HeadBlocker } from "../common/components/HeadBlocker";
import { ModalProvider } from "../common/contexts/modal";

const colors = {
  blue: "#009FE3",
  green: "#33665e",
  lightMint: "#DAF2F1",
};

export default class CaisyLandingPage extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps: any = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    pageProps.host = ctx.req?.headers?.host ?? null;
    return { pageProps };
  }

  render() {
    //@ts-ignore
    const { Component, pageProps, err } = this.props;

    const websiteDisabled =
      pageProps?.CompanyInformationPrivate?.websiteDisabled?.value === true;
    if (websiteDisabled) {
      return <h1>Website vorübergehend offline</h1>;
    }
    const privacyLink =
      pageProps?.Footer?.Navigationspunkte?.[1]?.slug ||
      "imprint-datenschutz#Datenschutz";
    const template = pageProps?.Settings?.template || DEFAULT_TEMPLATE;
    let GlobalStyle: any = undefined;
    let theme: any = undefined;

    switch (template) {
      case "t0":
        GlobalStyle = T0GlobalStyle;
        theme = T0Theme;
        break;

      case "t27":
        GlobalStyle = T27GlobalStyle;
        break;

      case "t26":
        GlobalStyle = T26GlobalStyle;
        theme = T26Theme;
        break;

      case "t25":
        GlobalStyle = T25GlobalStyle;
        theme = T25Theme;
        break;

      case "t24":
        GlobalStyle = T24GlobalStyle;
        theme = T24Theme;
        break;

      case "t23":
        GlobalStyle = T23GlobalStyle;
        theme = T23Theme;
        break;

      case "t22":
        GlobalStyle = T22GlobalStyle;
        theme = T22Theme;
        break;

      case "t21":
        GlobalStyle = T21GlobalStyle;
        theme = T21Theme;
        break;
      case "t20":
        GlobalStyle = T20GlobalStyle;
        theme = T20Theme;
        break;
      case "t19":
        GlobalStyle = T19GlobalStyle;
        theme = T19Theme;
        break;
      case "t18":
        GlobalStyle = T18GlobalStyle;
        theme = T18Theme;
        break;
      case "t17":
        GlobalStyle = T17GlobalStyle;
        theme = T17Theme;
        break;
      case "t16":
        GlobalStyle = T16GlobalStyle;
        theme = T16Theme;
        break;
      case "t15":
        GlobalStyle = T15GlobalStyle;
        theme = T15Theme;
        break;
      case "t14":
        GlobalStyle = T14GlobalStyle;
        theme = T14Theme;
        break;
      case "t13":
        GlobalStyle = T13GlobalStyle;
        theme = T13Theme;
        break;
      case "t12":
        GlobalStyle = T12GlobalStyle;
        theme = T12Theme;
        break;
      case "t11":
        GlobalStyle = T11GlobalStyle;
        theme = T11Theme;
        break;
      case "t10":
        GlobalStyle = T10GlobalStyle;
        theme = T10Theme;
        break;
      case "t9":
        GlobalStyle = T9GlobalStyle;
        theme = T9Theme;
        break;
      case "t8":
        GlobalStyle = T8GlobalStyle;
        theme = T8Theme;
        break;
      case "t7":
        GlobalStyle = T7GlobalStyle;
        theme = T7Theme;
        break;
      case "t6":
        GlobalStyle = T6GlobalStyle;
        theme = T6Theme;
        break;
      case "t5":
        GlobalStyle = T5GlobalStyle;
        theme = T5Theme;
        break;
      case "t4":
        GlobalStyle = T4GlobalStyle;
        theme = T4Theme;
        break;
      case "t3":
        GlobalStyle = T3GlobalStyle;
        theme = T3Theme;
        break;
      case "t2":
        GlobalStyle = T2GlobalStyle;
        theme = T2Theme;
        break;
      case "t1":
        GlobalStyle = T1GlobalStyle;
        theme = T1Theme;
        break;
      default:
        GlobalStyle = T1GlobalStyle;
        theme = T1Theme;
        break;
    }

    const primary =
      pageProps?.Settings?.themeColorPrimary ||
      ((pageProps?.Settings?.template === "t1" ||
        pageProps?.Settings?.template === "t2") &&
        "#d40046") ||
      colors.blue;
    const secondary = pageProps?.Settings?.themeColorSecondary || colors.green;
    const backgroundColor = pageProps?.Settings?.themeBackgroundColor;
    const themeTextColor = pageProps?.Settings?.themeTextColor;
    const pageTheme = { ...theme, primary, secondary, themeTextColor };
    const ccmActive = pageProps?.CompanyInformationPrivate?.ccm?.active;
    const primaryColor = pageProps?.Settings?.themeColorPrimary;

    return (
      <>
        {!pageProps.shouldNotUseDefaultTheme ? (
          <GlobalStyle theme={pageTheme} backgroundColor={backgroundColor} primaryColor={primaryColor}/>
        ) : null}
        <ThemeProvider
          theme={pageProps.shouldNotUseDefaultTheme ? {} : pageTheme}
        >
          <SEO host={pageProps.host} {...pageProps} />
          <HeadBlocker></HeadBlocker>
          <ModalProvider>
            <Component {...pageProps} err={err} />
          </ModalProvider>
        </ThemeProvider>
        {!pageProps?.Settings?.disableCookieBanner && !ccmActive ? (
          <CookieBanner
            privacyLink={privacyLink}
            pageProps={pageProps}
            theme={pageTheme}
          />
        ) : null}
      </>
    );
  }
}
