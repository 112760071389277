import { createGlobalStyle, css } from "styled-components";
import { PhotoGalleryStyleGlobal } from "../../../common/components/PhotoGalleryVisualization/PhotoGalleryStyleGlobal.style";

import { reset } from "src/common/constants/reset";

const base = css`
    h1,h2,h3,h4,h5,h6,p,a,li,ul,span,div {
        font-family: "Exo", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 15px;
        box-sizing: border-box;
    }
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      line-height: 160% !important;
    }
    a {
      color: black;
      cursor: pointer;
    }
    b {
      font-weight: 600;
    }
    span {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
    body{
        background: white;
        overflow-x: hidden;
        height: 100%;
    }
    html{
        min-height: 100%;
    }
    strong {
      font-weight: 600;
    }
    ul {
      padding-left: 35px;
      list-style-position: inside;
      * {
        list-style-position: inside;
      }
      li {
        display: list-item !important;
        list-style: disc;
      }
    }
    ol {
      padding-left: 35px;
      list-style-position: inside;
      * {
        list-style-position: inside;
      }
      li {
        display: list-item;
        list-style: decimal;
      }
    }
    @font-face {
        font-family: 'Exo';
        src: url("/fonts/Exo-ExtraLight.ttf") format("truetype");
        font-style: normal;
        font-weight: 200;
    }
    @font-face {
        font-family: 'Exo';
        src: url("/fonts/Exo-Light.ttf") format("truetype");
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: 'Exo';
        src: url("/fonts/Exo-Regular.ttf") format("truetype");
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: 'Exo';
        src: url("/fonts/Exo-Medium.ttf") format("truetype");
        font-style: normal;
        font-weight: 500;
    }
    @font-face {
        font-family: 'Exo';
        src: url("/fonts/Exo-SemiBold.ttf") format("truetype");
        font-style: normal;
        font-weight: 600;
    }
    @font-face {
        font-family: 'Exo';
        src: url("/fonts/Exo-Bold.ttf") format("truetype");
        font-style: normal;
        font-weight: 700;
    }
    ${PhotoGalleryStyleGlobal};
    [data-gallery]{
      padding: 40px !important;
      margin: auto !important;
    }
`;

export const GlobalStyle = createGlobalStyle`

  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
