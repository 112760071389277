import { reset } from "src/common/constants/reset";

import { createGlobalStyle, css } from "styled-components";

import { PhotoGalleryStyleGlobal } from "../../../common/components/PhotoGalleryVisualization/PhotoGalleryStyleGlobal.style";

const base = css<{ backgroundColor: string; theme?: any }>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  ul,
  span,
  div {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
    box-sizing: border-box;
    hyphens: none;
  }
  ul,
  ol {
    margin-left: 28px;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    line-height: 140% !important;
  }
  a {
    color: ${({ theme }) => theme?.themeTextColor || "black"};
    cursor: pointer;
  }
  body {
    background-color: ${({ backgroundColor }) => backgroundColor || "white"};
    overflow-x: hidden;
    height: 100%;
  }
  html {
    min-height: 100%;
  }
  strong {
    font-weight: 600;
  }
  @font-face {
    font-family: "Open Sans";
    src: url("/fonts/OpenSans-Light.woff2") format("woff2");
    font-style: normal;
    font-display: swap;
    font-weight: 300;
  }
  @font-face {
    font-family: "Open Sans";
    src: url("/fonts/OpenSans-Regular.ttf") format("ttf");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/poppins300.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/poppins400.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/poppins700.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "ahb-icons";
    src: url("/fonts/ahb_icons-webfont.woff") format("woff");
    font-style: normal;
    font-weight: 400;
  }

  em {
    font-style: italic;
  }

  ${PhotoGalleryStyleGlobal};

  td:not(:first-child) {
    padding-left: 1rem;
  }
  a.pdf {
    &:after {
      background-image: url("/images/t3_download_transparent.png");
      background-size: 38px 39px;
      background-repeat: no-repeat;
      content: "";
      width: 38px;
      position: absolute;
      height: 39px;
      margin-top: -10px;
      padding-left: 5px;
      /* margin-left: 12px; */
    }
    margin-right: 40px;
  }
  a.kuechenplaner {
    font-size: 2em;
  }
  p {
    line-height: 1.57143;
  }

  div.caption-description {
    background-color: #717171;
    color: white;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
    padding: 10.5px;
  }
  .cpg {
    margin-top: 20px;
  }

  div.cpg-item-img.no-img {
    display: none;
  }

  [data-gallery-slider] {
    .slick-track {
      .slick-slide {
        height: 100%;
        div {
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .ccm-settings-summoner {
      bottom: 110px !important;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
