export default {
  black: "#000000",
  white: "#ffffff",
  textColor: "#777878",
  textColorHeadings: "#796662",
  textColorLight: "#adadad;",
  textColorInverted: "#aaaaaa",
  black50: "#505050",
  highlight: "#ffffff",
  white80: "rgba(255,255,255, 0.8)",
  primary: "#adadad",
  secondary: "#f6931e",
  trans50: "rgba(255,255,255, 0.5)",
  bg: "#adadad",
  bgInverted: "#6b6b6b",
  bgLight: "#FEE580",
  bgProduct: "#cc5d61",
};
