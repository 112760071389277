import { createGlobalStyle, css } from "styled-components";
import { PhotoGalleryStyleGlobal } from "../../../common/components/PhotoGalleryVisualization/PhotoGalleryStyleGlobal.style";

import { reset } from "src/common/constants/reset";
import { MIN_GOLD, MIN_PLATINUM } from "./mediaquerys";

const base = css`
    .brand-area-container {
        padding: 0;
    }
    .borderthing {
        position: relative;
        margin-bottom: 27px;
        position: relative;
        border: solid 1px ${(props) => props.theme.secondary};
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -20px;
            left: -20px;
            z-index: 1;
            border-bottom: 1px solid ${(props) => props.theme.secondary};
            background: white;
            width: 40px;
            height: 40px;
            transform: rotate(-45deg);
        }
        width: 100%;
        padding: 27px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    li,
    ul,
    span,
    div {
        font-family: "Exo", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        font-size: 15px;
        box-sizing: border-box;
    }
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        line-height: 140% !important;
    }
    a {
        color: black;
        cursor: pointer;
    }
    body {
        background: white;
        overflow-x: hidden;
        height: 100%;
    }
    html {
        min-height: 100%;
    }
    strong {
        font-weight: 600;
    }
    ul {
        padding-left: 35px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item !important;
            list-style: disc;
        }
    }
    ol {
        padding-left: 35px;
        list-style-position: inside;
        * {
            list-style-position: inside;
        }
        li {
            display: list-item;
            list-style: decimal;
        }
    }
    td:not(:first-child) {
        padding-left: 1rem;
    }
    @font-face {
        font-family: "Exo";
        src: url("/fonts/Exo-ExtraLight.ttf") format("truetype");
        font-style: normal;
        font-weight: 200;
    }
    @font-face {
        font-family: "Exo";
        src: url("/fonts/Exo-Light.ttf") format("truetype");
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: "Exo";
        src: url("/fonts/Exo-Regular.ttf") format("truetype");
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: "Exo";
        src: url("/fonts/Exo-Medium.ttf") format("truetype");
        font-style: normal;
        font-weight: 500;
    }
    @font-face {
        font-family: "Exo";
        src: url("/fonts/Exo-SemiBold.ttf") format("truetype");
        font-style: normal;
        font-weight: 600;
    }
    @font-face {
        font-family: "Exo";
        src: url("/fonts/Exo-Bold.ttf") format("truetype");
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: "ahb-icons";
        src: url("/fonts/ahb_icons-webfont.woff") format("woff");
        font-style: normal;
        font-weight: 400;
    }
    ${PhotoGalleryStyleGlobal};

    .pac-container:after {
        content: none !important;
    }
    em {
        font-style: italic;
    }
`;

const GOLD = css`
    [data-gallery] {
        max-width: min(calc(100vw - 412px), 948px);
        .slick-track,
        .slick-list {
            [data-image] {
                img {
                    height: auto !important;
                    aspect-ratio: 16/9 !important;
                }
            }
            max-height: auto !important;
        }
    }
`;

const PLATINUM = css`
    [data-gallery] {
        margin-right: 0;
        margin-left: 0;
        max-width: min(calc(100vw - 500px), 948px);
        .slick-track,
        .slick-list {
            [data-image] {
                img {
                    height: auto !important;
                    aspect-ratio: 16/9 !important;
                }
            }
            max-height: auto !important;
        }
    }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
  ${MIN_GOLD`${GOLD}`};
  ${MIN_PLATINUM`${PLATINUM}`};

`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
  ${MIN_PLATINUM`${PLATINUM}`};

`;
