import { css } from "styled-components";
import colors from "./colors";
export default {
  h1: css`
    font-family: Roboto,sans-serif;
    line-height: 1.4;
    font-weight: 300;
    font-size: 30px;
    -webkit-font-smoothing: antialiased;
    color: ${colors.textColorHeadings};
  `,
  // p h2 h3 etc etc
};
