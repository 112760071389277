import { createGlobalStyle, css } from "styled-components";
import { reset } from "src/common/constants/reset";
import color from "./colors";
interface IProps
{
  theme?: any;
}

const base = css`
    * {
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      font-size: 13px;
      line-height: 21px;
      color: ${color.textColor};
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      line-height: 140%;
    }
    strong{
      font-weight: 700;
    }
    p {
      line-height: 1.4;
    }
    h1,h2,h3,h4,h5,h6,p,a,li,ul,span,div {
      font-family: "Open Sans", sans-serif;
    }

    h1 {
        margin: 0 0 21px 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
    }
    h1,h2,h3,h4,h5,h6,ul,ol,p {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    ul,ol {
      padding-left: 40px;
    }
    a, span {
      color: inherit;
      font-size: inherit;
    }
    html, body {
      color: #404040;
    }
    body{
      height: 100%;
    }
    html{
       min-height: 100vh;
    }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
