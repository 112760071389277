import { css } from "styled-components";
import colors from "./colors";
export default {
  h1: css`
    width: 100%;
    line-height: 1.4;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.75;
    letter-spacing: .02em;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.textColorHeadings};
  `,
  // p h2 h3 etc etc
};
