import { createGlobalStyle, css } from "styled-components";
import { reset } from "src/common/constants/reset";

interface IProps {
  theme?: any;
}

const base = css`
  @font-face {
    font-family: "FuturaLTW01";
    font-weight: normal; // 400
    src: url("/lfonts/1475798/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2")
        format("woff2"),
      url("/lfonts/1475798/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff")
        format("woff");
  }

  @font-face {
    font-weight: bold; //700
    font-family: "FuturaLTW01";
    src: url("/lfonts/1475822/3cf9e1e8-63a2-497f-86ed-6b63d6de1986.woff2")
        format("woff2"),
      url("/lfonts/1475822/184895ac-545b-450b-9d3a-c0a0a7620977.woff")
        format("woff");
  }

  @font-face {
    font-family: "FuturaLTW01";
    font-weight: 900;
    src: url("/lfonts/1475822/3cf9e1e8-63a2-497f-86ed-6b63d6de1986.woff2")
        format("woff2"),
      url("/lfonts/1475822/184895ac-545b-450b-9d3a-c0a0a7620977.woff")
        format("woff");
  }

  * {
    font-family: "FuturaLTW01", Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    line-height: 140%;
  }

  strong {
    font-weight: 700;
  }

  p {
    line-height: 1.4;
    margin-bottom: 1.4rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  ul,
  span,
  div {
    font-family: "FuturaLTW01", Arial, sans-serif;
    font-weight: 300;
    font-size: 0.9375rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3 {
    margin: 0 0 21px 0;
    color: #fff;
    font-weight: lighter;
    font-size: 34px;
    line-height: 38px;
    margin-bottom: 1.4rem;
  }

  h4 {
    margin-bottom: 1.4rem;
  }

  ul {
    margin-left: 21px;
  }

  a,
  span {
    color: inherit;
    font-size: 15px;
  }

  html,
  body {
    background-color: #1d1d1b;
    color: #efefef;
  }

  body {
    background-color: #1d1d1b;
    height: 100%;
  }

  html {
    min-height: 100vh;
  }

  @font-face {
    font-family: "ahb-icons";
    src: url("/fonts/ahb_icons-webfont.woff") format("woff");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
