import { createGlobalStyle, css } from "styled-components";
import colors from "./colors";
import { reset } from "src/common/constants/reset";
interface IProps {
  theme?: any;
}

const base = css`
  :root {
    --color-primary: #f9b000;
  }

  @font-face {
    font-family: "HelveticaLTW01";
    font-weight: 500;
    src: url("/lfonts/1489452/7ce0a2f5-eb00-46aa-919c-5b3f3667646c.woff2")
        format("woff2"),
      url("/lfonts/1489452/cad22c74-45b4-4c49-9e6a-0cd3768a7bc7.woff")
        format("woff");
  }

  @font-face {
    font-family: "HelveticaLTW01";
    font-weight: 300;
    src: url("/lfonts/1489424/37953885-0443-4c72-a693-9152a9f5a901.woff2")
        format("woff2"),
      url("/lfonts/1489424/fcce277c-07e2-4c88-ad8b-a71b484e2cbe.woff")
        format("woff");
  }

  * {
    font-family: "HelveticaLTW01", Arial, sans-serif;
    box-sizing: border-box;
  }

  html {
    min-height: 100vh;
  }

  body {
    height: 100%;
    font-family: "HelveticaLTW01", Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: ${colors.textColor};
    background: url(/images/kw_bg_pattern.png) repeat;
    /* border-bottom: 10px solid white; */
  }

  strong {
    font-weight: 700;
  }

  p {
    font-weight: 300;
    line-height: 1.4;
    font-size: 0.9375em;
  }

  h1 {
    margin-bottom: 1.4rem;
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0.02em;
    line-height: 1;
  }
  h2 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }
  h3 {
    font-size: 1.33333rem;
    letter-spacing: 0.02em;
  }
  h4 {
    font-size: 0.86667rem;
    line-height: 1.61538;
    color: #6b6b6b;
  }
  li,
  a,
  span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    list-style-type: disc;
  }

  li {
    line-height: 21px;
  }

  @font-face {
    font-family: "ahb-icons";
    src: url("/fonts/ahb_icons-webfont.woff") format("woff");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  .frc-container {
    .frc-icon {
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }
    .frc-progress {
      background-color: #444;
    }

    .frc-progress::-webkit-progress-bar {
      background: #444;
    }

    .frc-progress::-webkit-progress-value {
      background: var(--color-primary);
    }

    .frc-progress::-moz-progress-bar {
      background: var(--color-primary);
    }
    .frc-content {
      .frc-button {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
