export default {
  black: "#000000",
  white: "#ffffff",
  textColor: "#404040;",
  textColor1: "#ffffff",
  black50: "#505050",
  highlight: "#ffffff", //"#D30032"
  white80: "rgba(255,255,255, 0.8)",
  prime: "#60BA2C", //"#D30032"
  primary: "#FA8800",
  green: "#60BA2C",
  trans50: "rgba(255,255,255, 0.5)", // rgba(254,229,128,0.46)
  bg: "#60BA2C", // "#000000"
  bgLight: "#FEE580", // "rgba(255,255,255, 0.5)"
  productPrime: "#60BA2C", // "rgba(255,255,255, 0.5)"
  productBg: "#ED1E79", // "rgba(255,255,255, 0.5)"
  light_blue: "#80C7DC",
};
