import { createGlobalStyle, css } from "styled-components";
import { reset } from "src/common/constants/reset";
import color from "./colors";
import text from "./textStyles";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

interface IProps
{
  theme?: any;
}

const base = css`
  * {
    box-sizing: border-box;
  }

  html{
    min-height: 100vh;
  }

  body{
    height: 100%;
    color: ${color.textColor};
    font-family: Mulish, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5rem;

    @media (${BREAKPOINTS_DM.gold_1024}) {
      padding-top: 50px;
    }
  }


  strong{
    font-weight: 700;
  }

  h1 {
    ${text.h1}
    margin-bottom: 21px;
  }

  h2,h3,h4,h5,h6 {
    position: relative;
    font-size: 20px;
    text-align: left;
  }

  p, ul, ol {
    margin-bottom: 10px;
  }

  li {
    line-height: 26px;
    padding-left: 5px;
  }

  table {
    margin-bottom: 20px;
    > tr > td {
    }
  }

  h1,h2,h3,h4,h5,h6 {
    color: ${color.textColorHeadings};
    line-height: 1.4;
  }

  a, span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  button, a {
    &.primary {
      display: inline-block;
      position: relative;
      color: ${color.white};
      border: none;
      background-color: ${color.primary};
      padding: 10px 16px;
      border-radius: 4px;
      line-height: 22px;
      cursor: pointer;

      &.inverted {
        color: ${color.primary};
        background-color: ${color.white};
      }

      :hover {
        background-color: ${color.secondary};

        &, * {
          color: ${color.primary};
        }
      }

      &, p, span {
        margin: 0;
        font-family: Mulish, sans-serif;
        font-weight: 300;
        font-size: 14px;
      }


    }
  }

  label {
    color: #5a5a5a;
    font-weight: 300;
    margin: 0;

    p {
      margin-bottom: 0;
    }
  }

  input:not([type=checkbox]), textarea {
    font-family: Mulish, sans-serif;
    display: block;
    width: 100%;
    color: ${color.textColor};

    background: #F1F1F1;
    padding: 1em;
    border: 0;
    outline: 0;
  }

  /* vietnamese */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Pttg83HX_SGhgqk2jogaqRFB_ie_Vo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Pttg83HX_SGhgqk2johaqRFB_ie_Vo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Pttg83HX_SGhgqk2jovaqRFB_ie.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Pttg83HX_SGhgqk2jogaqRFB_ie_Vo.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Pttg83HX_SGhgqk2johaqRFB_ie_Vo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Pttg83HX_SGhgqk2jovaqRFB_ie.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Ptvg83HX_SGhgqk0AotYKNnBcif.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Ptvg83HX_SGhgqk0QotYKNnBcif.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Ptvg83HX_SGhgqk3wotYKNnBQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Ptvg83HX_SGhgqk0AotYKNnBcif.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Ptvg83HX_SGhgqk0QotYKNnBcif.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/mulish/v3/1Ptvg83HX_SGhgqk3wotYKNnBQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
      font-family: 'ahb-icons';
      src: url("/fonts/ahb_icons-webfont.woff") format("woff");
      font-style: normal;
      font-display: swap;
      font-weight: 400;
  }
  a.pdf {
    &:after {
      font-family: ahb-icons;
      content: "D";
      font-size: 48px;
      width: 38px;
      position: absolute;
      height: 39px;
      margin-top: -20px;
      padding-left: 5px;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
