import { reset } from "src/common/constants/reset";

import { createGlobalStyle, css } from "styled-components";

import { PhotoGalleryStyleGlobal } from "../../../common/components/PhotoGalleryVisualization/PhotoGalleryStyleGlobal.style";
import { BREAKPOINTS_DM } from "./mediaquerys";
import t27VariablesBaseStyle from "../../t27/constants/baseStyle";

const base = css<{ backgroundColor: string; theme?: any; primaryColor: string }>`
    ${t27VariablesBaseStyle}

    body {
        background-color: var(--back-1);
        overflow-x: hidden;
        height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    li,
    ul,
    span,
    div {
        font-family: Arial, sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        @media (${BREAKPOINTS_DM.silver_768}) {
            font-size: 18px;
        }
        @media (${BREAKPOINTS_DM.platinum_1366}) {
            font-size: 24px;
        }
        box-sizing: border-box;
        hyphens: none;
    }
    ul,
    ol {
        margin-left: 28px;
    }
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    a {
        color: ${({ theme }) => theme?.themeTextColor || "black"};
        cursor: pointer;
    }
    html {
        min-height: 100%;
    }
    strong {
        font-weight: 600;
    }
    em {
        font-style: italic;
    }

    ${PhotoGalleryStyleGlobal};

    div.cpg-item-img.no-img {
        display: none;
    }

    [data-gallery-slider] {
        .slick-track {
            .slick-slide {
                height: 100%;
                div {
                    height: 100%;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .ccm-settings-summoner {
            bottom: 110px !important;
        }
    }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
