export default {
  black: "#000000",
  white: "#ffffff",
  textColor: "#4d4d4c",
  textColorHeadings: "#515151",
  textColorLight: "#adadad;",
  textColorInverted: "#aaaaaa",
  black50: "#505050",
  highlight: "#ffffff",
  white80: "rgba(255,255,255, 0.8)",
  primary: "#c8bfaf",
  secondary: "#4d4d4c",
  trans50: "rgba(255,255,255, 0.5)",
  bg: "#c8bfaf",
  bgInverted: "#6b6b6b",
  bgLight: "#f5f5f5",
  bgProduct: "#cc5d61",
};
