import { css } from "styled-components";

export default {
  h1: css`
    margin: 0 0 21px 0;
  `,
  h3: css`
      margin: 0 0 21px 0;
      font-size: 25px;
  `,
  // p h2 h3 etc etc
};
