export default {
    black: "#000000",
    white: "#ffffff",
    textColor1: "#000000",
    highlight: "#D30032", //"#ffffff"
    white80: "rgba(255,255,255, 0.8)",
    prime: "#D30032", 
    orange: "#F29315",
    red: "#D30032",
    trans50: "rgba(254,229,128,0.46)", 
    bg1: "#000000", 
    bgLight: "rgba(255,255,255, 0.5)",
    productPrime: "#d40046", // "rgba(255,255,255, 0.5)"
    productBgLight100: "#e6e7e9", // "rgba(255,255,255, 0.5)"
    productBgLight40: "rgba(255,255,255,.4)", // "rgba(255,255,255, 0.5)"
};
