export default {
  black: "#000000",
  white: "#ffffff",
  textColor: "#6b6b6b;",
  textColor1: "#ffffff",
  black50: "#505050",
  highlight: "#ffffff",
  white80: "rgba(255,255,255, 0.8)",
  primary: "#f6931e",
  secondary: "#f6931e",
  trans50: "rgba(255,255,255, 0.5)",
  bg: "#f6931e",
  bgInverted: "#6b6b6b",
  bgLight: "#e6e7e9",
};
