import colors from "./colors";
import textStyles from "./textStyles";

export const theme = {
  palette: colors,
  textStyles,
  maxWidth: 1100,
  box: `
    border: 1px solid #7d7261;
    padding: 20px;
  `
};
