import { createGlobalStyle, css } from "styled-components";
import { reset } from "src/common/constants/reset";

interface IProps {
  theme?: any;
}

const base = css`
  :root {
    --color-primary: #92c2d6;
  }

  @font-face {
    font-family: "SuperGroteskW01";
    font-weight: 500;
    src: url("lfonts/7350568/3bb69d5c-9066-493a-9743-65bf9b0d497c.woff2")
        format("woff2"),
      url("lfonts/7350568/56562da5-d265-471a-adf8-e1127a519788.woff")
        format("woff");
  }

  @font-face {
    font-family: "SuperGroteskW01";
    font-weight: 400;
    src: url("/lfonts/7350563/0ba1edc0-09d6-4f17-89e5-b97d2da37381.woff2")
        format("woff2"),
      url("/lfonts/7350563/85454150-ed67-4ca2-b0b0-18c0bb02d477.woff")
        format("woff");
  }
  
  @font-face {
    font-family: "ahb-icons";
    src: url("/fonts/ahb_icons-webfont.woff") format("woff");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  * {
    font-family: "SuperGroteskW01", sans-serif;
    font-size: 15px;
    line-height: 21px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    line-height: 140%;
  }
  strong {
    font-weight: 700;
  }
  p {
    line-height: 1.4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  li,
  ul,
  span,
  div {
    font-family: "SuperGroteskW01", sans-serif;
    font-weight: 500;
    font-size: 15px;
    box-sizing: border-box;
  }
  p,
  a,
  li,
  ul,
  span,
  div {
    font-family: "SuperGroteskW01", sans-serif;
    font-weight: 400;
  }
  h1 {
    margin: 0 0 21px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  a,
  span {
    color: inherit;
    font-size: 15px;
  }
  html,
  body {
    background-color: #fff;
    color: #85b2c3;
  }
  body {
    background-color: #fff;
    height: 100%;
  }
  html {
    min-height: 100vh;
  }

  .frc-container {
    .frc-icon {
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }
    .frc-progress {
      background-color: #444;
    }

    .frc-progress::-webkit-progress-bar {
      background: #444;
    }

    .frc-progress::-webkit-progress-value {
      background: var(--color-primary);
    }

    .frc-progress::-moz-progress-bar {
      background: var(--color-primary);
    }
    .frc-content {
      .frc-button {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
