import { createGlobalStyle, css } from "styled-components";
import colors from "./colors";
import { reset } from "src/common/constants/reset";
interface IProps {
  theme?: any;
}

const base = css`
  :root {
    --color-primary: #736654;
  }

  * {
    font-family: "HelveticaLTW01", Arial, sans-serif;
    box-sizing: border-box;
  }
  strong {
    font-weight: 700;
  }
  p {
    font-size: 0.9375em;
    line-height: 1.4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  ul,
  span,
  div {
    font-weight: 300;
    box-sizing: border-box;
  }
  h1 {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: lighter;
    letter-spacing: 0.02em;
    line-height: 1;
    padding-bottom: 1rem;
  }
  a,
  span {
    color: inherit;
    font-size: inherit;
  }
  h2 {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: lighter;
    letter-spacing: 0.02em;
    line-height: 1;
    padding-bottom: 1rem;
  }
  h3 {
    padding-bottom: 1rem;
  }

  body {
    height: 100%;
    font-family: "HelveticaLTW01", Arial, sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: ${colors.textColor};
    background: url(/images/kg_bg_pattern.png) repeat;
    padding-bottom: 21px;
  }
  html {
    min-height: 100vh;
  }
  @font-face {
    font-family: "ahb-icons";
    src: url("/fonts/ahb_icons-webfont.woff") format("woff");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: "HelveticaLTW01";
    font-weight: 300;
    src: url("/lfonts/1489424/37953885-0443-4c72-a693-9152a9f5a901.woff2")
        format("woff2"),
      url("/lfonts/1489424/fcce277c-07e2-4c88-ad8b-a71b484e2cbe.woff")
        format("woff");
  }

  .frc-container {
    .frc-icon {
      fill: var(--color-primary);
      stroke: var(--color-primary);
    }
    .frc-progress {
      background-color: #cccccc;
    }

    .frc-progress::-webkit-progress-bar {
      background: #cccccc;
    }

    .frc-progress::-webkit-progress-value {
      background: var(--color-primary);
    }

    .frc-progress::-moz-progress-bar {
      background: var(--color-primary);
    }
    .frc-content {
      .frc-button {
        color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
