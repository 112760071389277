import { createGlobalStyle, css } from "styled-components";
import { PhotoGalleryStyleGlobal } from "../../../common/components/PhotoGalleryVisualization/PhotoGalleryStyleGlobal.style";

import { reset } from "src/common/constants/reset";

interface IProps {
  theme?: any;
}

const base = css`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    line-height: 140%;
  }
  p {
    line-height: 1.4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  ul,
  span,
  div {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 15px;
    box-sizing: border-box;
  }
  a,
  span {
    color: inherit;
    font-size: inherit;
  }
  html,
  body {
    background: url("/images/background_silver.png") repeat;
  }
  body {
    overflow-x: hidden;
    height: 100%;
  }
  html {
    min-height: 100%;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/poppins300.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/poppins400.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/poppins700.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "ahb-icons";
    src: url("/fonts/ahb_icons-webfont.woff") format("woff");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }
  ${PhotoGalleryStyleGlobal};
`;

export const GlobalStyle = createGlobalStyle`

  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
