import { createGlobalStyle, css } from "styled-components";
import { reset } from "src/common/constants/reset";

interface IProps
{
  theme?: any;
}

const base = css`
    * {
      font-family: Montserrat,Helvetica,Arial,sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      line-height: 140%;
    }
    strong{
      font-weight: 700;
    }
    p {
      line-height: 1.4;
    }
    h1,h2,h3,h4,h5,h6,p,a,li,ul,span,div {
        font-family: Montserrat,Helvetica,Arial,sans-serif;
        font-weight: 400;
        font-size: 15px;
        box-sizing: border-box;
    }
    h1 {
        margin: 0 0 21px 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
    }
    a, span {
      color: inherit;
      font-size: inherit;
    }
    html, body {
      background-color: #fff;
      color: #EFEFEF;
    }
    body{
      background-color: #fff;// background-image: -webkit-radial-gradient(300px 200px,ellipse cover,#f8f8f8 0,#d0d2d3 80%);
      height: 100%;
    }
    html{
       min-height: 100vh;
    }
    @font-face {
        font-family: "ahb-icons";
        src: url("/fonts/ahb_icons-webfont.woff") format("woff");
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/Montserrat-ExtraLight.ttf") format("truetype");
        font-style: normal;
        font-weight: 200;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/Montserrat-Light.ttf") format("truetype");
        font-style: normal;
        font-weight: 300;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/Montserrat-Regular.ttf") format("truetype");
        font-style: normal;
        font-weight: 400;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/Montserrat-Medium.ttf") format("truetype");
        font-style: normal;
        font-weight: 500;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/Montserrat-SemiBold.ttf") format("truetype");
        font-style: normal;
        font-weight: 600;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("/fonts/Montserrat-Bold.ttf") format("truetype");
        font-style: normal;
        font-weight: 700;
    }
    @font-face {
        font-family: "ahb-icons";
        src: url("/fonts/ahb_icons-webfont.woff") format("woff");
        font-style: normal;
        font-weight: 400;
    }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
