import { css } from "styled-components";

export default {
    h1: css`
      margin: 30px 0 0 0;
      color: ${({ theme }) => theme.palette.black};
      font-weight: 400;
      font-size: 20px;
      line-height: 15px;
  `,
};
