import { createGlobalStyle, css } from "styled-components";
import { PhotoGalleryStyleGlobal } from "../../../common/components/PhotoGalleryVisualization/PhotoGalleryStyleGlobal.style";

import { reset } from "src/common/constants/reset";

const base = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  ul,
  span,
  div {
    font: 0.9375em/1.4 Montserrat, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    box-sizing: border-box;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    line-height: 140% !important;
  }
  a {
    color: black;
    cursor: pointer;
  }
  body {
    background: white;
    overflow-x: hidden;
    height: 100%;
  }
  html {
    min-height: 100%;
    font: 0.9375em/1.4 Montserrat, Helvetica, Arial, sans-serif;
  }
  strong {
    font-weight: 600;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-ExtraLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 200;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
  }
  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: "ahb-icons";
    src: url("/fonts/ahb_icons-webfont.woff") format("woff");
    font-style: normal;
    font-weight: 400;
  }
  ${PhotoGalleryStyleGlobal};
  [data-gallery] {
    padding: 24px !important;
  }

  .cpg{
    margin-right: 13.5px;
    > div > div {
      grid-gap: 14px;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`

  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
