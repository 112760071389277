import { createGlobalStyle, css } from "styled-components";
import { reset } from "src/common/constants/reset";

interface IProps {
  theme?: any;
}

const base = css`
  @font-face {
    font-family: "Open Sans";
    src: url("/fonts/OpenSans-Regular.ttf") format("ttf");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }
  @font-face {
    font-family: "Open Sans";
    src: url("/fonts/OpenSans-Bold.ttf") format("ttf");
    font-style: normal;
    font-display: swap;
    font-weight: 700;
  }
  * {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    line-height: 140%;
  }
  strong {
    font-weight: 700;
  }
  p {
    line-height: 1.4;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  li,
  ul,
  span,
  div {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    box-sizing: border-box;
  }
  h1 {
    margin: 0 0 21px 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  }
  a,
  span {
    color: inherit;
    font-size: inherit;
  }
  html,
  body {
    background-image: white;
    color: white;
  }
  body {
    background-image: white;
    height: 100%;
  }
  html {
    min-height: 100vh;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/fonts/poppins300.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/fonts/poppins400.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/fonts/poppins700.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
    font-family: "ahb-icons";
    src: url("/fonts/ahb_icons-webfont.woff") format("woff");
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }
  
  a.pdf {
    &:after {
      font-family: ahb-icons;
      content: "D";
      font-size: 48px;
      width: 38px;
      position: absolute;
      height: 39px;
      margin-top: -20px;
      padding-left: 5px;
    }
  }

  .cpg-item-head{
    &, h2, strong{
      font-weight: 700;
    }
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${base};
`;

export const GlobalStyleStoybook = createGlobalStyle`
  ${reset};
  ${base};
`;
