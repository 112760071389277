export default {
    black: "#000000",
    white: "#ffffff",
    textColor1: "#ffffff",
    highlight: "#ffffff", //"#D30032"
    white80: "rgba(255,255,255, 0.8)",
    prime: "#ffca00", //"#D30032"
    orange: "#F29315",
    red: "#D30032",
    trans50: "rgba(255,255,255, 0.5)", // rgba(254,229,128,0.46)
    bg1: "#F29315", // "#000000"
    bgLight: "#FEE580", // "rgba(255,255,255, 0.5)"
    productPrime: "#d40046", // "rgba(255,255,255, 0.5)"
    productBg1: "#e6e7e9", // "rgba(255,255,255, 0.5)"
};
